import "../assets/styles/loaders.css";

const Loading = ({show}: {show: boolean}) => {
    return (
        <div
        className="loading-spinner-line"
        style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.3)",
            zIndex: "999",
            display: show ? "flex" : "none",
            top: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div className="loader"></div>
        </div>
    )
}

export default Loading;