import axios from 'axios';
import { supabase } from './supabase';

const BASE_URL = process.env.REACT_APP_PROXY;

export const credentialsRepo = {
    searchDomain: async (domain: string, systemsPage: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/capi/search/domain_systems/${domain}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    systems_per_page: 10,
                    systems_page: systemsPage,
                }
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    searchEmailDomain: async (emailDomain: string, systemsPage: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/capi/search/email_domain/${emailDomain}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    systems_per_page: 10,
                    systems_page: systemsPage,
                }
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    searchCredentialsDomain: async (uuid: string, domain: string, credsPage: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/capi/search/creds_domain/${uuid}/${domain}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    creds_per_page: 10,
                    creds_page: credsPage
                }
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    searchCredentialsEmailDomain: async (uuid: string, domain: string, credsPage: number) => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/capi/search/creds_email/${uuid}/${domain}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    creds_per_page: 10,
                    creds_page: credsPage
                }
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    }
}